import {Campaign, IUserNotification} from '../model/campaign/Campaign';
import {BaseModel} from '../model/shared/BaseModel';
import {User} from '../model/user/User';
import {getVerifyRoute} from '../shared/functions/Functions';
import {requests} from '../utils';
import {
    AUTH_AGENCY_URL,
    AUTH_BRAND_URL,
    AUTH_INFLUENCER,
    AUTH_ME,
    AUTH_REGISTER_STEP_URL,
    AUTH_RESET_PASSWORD,
    AUTH_TERMS,
    BASE_URL,
    LOGIN_URL,
    VERIFY_2FA_RESEND_URL,
    VERIFY_2FA_URL
} from '../utils/endpoints';
import {IForgot, IValidate} from './forgot/dto/IForgot';
import {IInvitedInfluencerState} from './register/agency/dto/IRegisterAgency';
import {
    IRegisterAdmin,
    IRegisterBillingInfos,
    IRegisterInvite,
    IRegisterPreference,
    IRegisterStep,
} from './register/brand/dto/IRegister';
import {IRegistrationStepTwoRequest} from './register/brand/dto/IRegistrationStepTwoRequest';

import {IEmployeeRegister} from './register/employee/dto/IEmployeeRegister';
import {IInfluencerBillingRequest} from './register/influencer/dto/IInfluencerBillingRequest';
import {IInfluencerCategories} from './register/influencer/dto/IInfluencerCategories';
import {IRegisterInfluencer} from './register/influencer/dto/IRegisterInfluencer';

interface ILoginResponse {
    userData: User;
    refreshToken: string;
    token: string;
}

export const AuthService = {
    login: (username: string, password: string): Promise<ILoginResponse> => requests.post(LOGIN_URL, {
        username,
        password
    }),
    register: (body: IRegisterAdmin) => requests.post(`${AUTH_BRAND_URL}/register/admin-account`, body),
    registerInfluencer: (body: IRegisterInfluencer): Promise<User> => requests.post(`${AUTH_INFLUENCER}/register`, body),
    getUserDataById: (URL: string, id: string) => requests.get(`${getVerifyRoute(URL)}/verify/${id}`),
    getInfluencerData: (id: string): Promise<User> => requests.get(`${AUTH_INFLUENCER}/verify/${id}`),
    verifyInfluencer: (id: string, body: IRegistrationStepTwoRequest): Promise<User> => requests.post(`${AUTH_INFLUENCER}/verify/${id}`, body),
    resetVerifyCodeInfluencer: (email: string) => requests.post(`${AUTH_INFLUENCER}/resend/verify`, {email}),
    categoriesInfluencer: (body: IInfluencerCategories): Promise<User> => requests.put(`${AUTH_INFLUENCER}/categories`, body),
    billingInfoInfluencer: (body: IInfluencerBillingRequest): Promise<User> => requests.put(`${AUTH_INFLUENCER}/company-billing`, body),
    verifyUserData: (id: string, body: IRegistrationStepTwoRequest) => requests.post(`${AUTH_BRAND_URL}/verify/${id}`, body),
    resetVerifyCode: (email: string) => requests.post(`${AUTH_BRAND_URL}/resend/verify`, {
        email,
    }),
    registerEmployeeAccount: (body: IEmployeeRegister) => requests.post(`${AUTH_BRAND_URL}/register/employee-account`, body),
    getEmployeeCompany: (id: string) => requests.get(`${AUTH_BRAND_URL}/employee/company/${id}`),
    getAgencyCompany: (id: string): Promise<IInvitedInfluencerState> => requests.get(`${AUTH_AGENCY_URL}/influencer/company/${id}`),
    updateUserBillingInfos: (body: IRegisterBillingInfos) => requests.put(`${AUTH_BRAND_URL}/company-billing`, body),
    inviteEmployees: (body: IRegisterInvite) => requests.post(`${AUTH_BRAND_URL}/invite-employees`, body),
    inviteInfluencers: (body: IRegisterInvite) => requests.post(`${AUTH_BRAND_URL}/invite-influencers`, body),
    updateGlobalPreference: (body: IRegisterPreference) => requests.put(`${AUTH_BRAND_URL}/global-preference`, body),
    updateCurrentStep: (body: IRegisterStep) => requests.put(AUTH_REGISTER_STEP_URL, body),
    resetPassword: (body: IForgot) => requests.post(AUTH_RESET_PASSWORD, body),
    validatePassword: (body: IValidate) => requests.post(`${AUTH_RESET_PASSWORD}/validate`, body),
    getPersonalInfo: (body: any): Promise<User> => requests.post(AUTH_ME, body),
    updateTermsNotify: () => requests.post(`${AUTH_TERMS}`, {}),
    twoFactorAuthVerify: (id: string, twoFactorToken: string) => requests.post(`${VERIFY_2FA_URL}/${id}`, {twoFactorToken}),
    resetVerifyCode2FO: (email: string) => requests.put(`${VERIFY_2FA_RESEND_URL}`, {
        email,
    }),
    markAllNotification: () => requests.put(BASE_URL + `notification/read/all`, {}),
    markNotification: (id: number) => requests.put(BASE_URL + `notification/${id}`, {}),
    getNotifications: (params: any): Promise<BaseModel<IUserNotification[]>> => requests.get(BASE_URL + 'notification', params),
    getNotificationCampaigns: (params: any): Promise<BaseModel<Campaign[]>> => requests.get(BASE_URL + 'notification/dropdown', params)
};
