import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import DOMPurify from 'dompurify';

interface IConfirmationModalProps {
    title: string;
    description: string;
    htmlDescription?: any;
    iconUrl?: string;
    saveBtn?: string;
    cancelBtn?: string;
    action?: any;
    show: boolean;
    showSuccessButton?: boolean;
    closeModal: () => void;
    classText?: string; 
}

export function ConfirmationModal({
                                      action,
                                      description,
                                      title,
                                      show,
                                      closeModal,
                                      htmlDescription,
                                      saveBtn = 'yes',
                                      cancelBtn = 'close',
                                      iconUrl,
                                      showSuccessButton = true,
                                      classText
                                  }: IConfirmationModalProps) {
    const {t} = useTranslation();

    return (
        <Modal show={show}
                onHide={closeModal}
                className="modal-dark"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
        >
            <Box className="p-3">
                <div className="m-2 mt-4 d-flex align-items-center justify-content-center flex-column">
                    {!!iconUrl?.length && <div>
                        <img src={iconUrl} className="mb-3" alt="Icon placeholder"/>
                    </div>}
                    <h4 className="text-center">{title}</h4>
                </div>
                <div className="m-2 text-center text-muted">
                    <p className={`${classText ?? 'word-break'}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}/>
                    {htmlDescription}
                </div>

                <div className="text-center my-3 mb-4">
                    <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
                        {t(`general.button.${cancelBtn}`)}
                    </Button>
                    {showSuccessButton && <Button variant="primary" className="btn-sm mx-2" onClick={(e) => {
                        action();
                        closeModal();
                    }}>
                        {t(`general.button.${saveBtn}`)}
                    </Button>}
                </div>
            </Box>
        </Modal>
    );
}
